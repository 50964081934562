define("discourse/plugins/discourse-gamification/discourse/routes/gamification-leaderboard-index", ["exports", "@ember/service", "discourse/lib/ajax", "@glimmer/tracking", "discourse/routes/discourse"], function (_exports, _service, _ajax, _tracking, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    leaderboardgroup: (0, _tracking.tracked)(),
    async model() {
      const res = await (0, _ajax.ajax)(`/leaderboard`);
      const data = await (0, _ajax.ajax)(`/moyun/leaderboard_for_group`, {
        type: "POST"
      });
      res.users = data.users;
      res.leaderboardyour = true;
      if (!data.users) return;
      res.users = data.users.filter(item => item.username !== "General");
      return res;

      // return ajax(`/leaderboard`)
      //   .then((response) => {
      //     return response;
      //   })
      //   .catch(() => this.router.replaceWith("/404"));
    }
  });
});